define("ember-modal-dialog/components/liquid-tether-dialog", ["exports", "@ember-decorators/component", "@ember/object", "@ember/string", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/liquid-tether-dialog"], function (_exports, _component, _object, _string, _basicDialog, _liquidTetherDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  /* eslint-disable ember/no-computed-properties-in-native-classes */
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let LiquidTetherDialog = _exports.default = (_dec = (0, _component.layout)(_liquidTetherDialog.default), _dec2 = (0, _object.computed)('targetAttachment'), _dec(_class = (_class2 = class LiquidTetherDialog extends _basicDialog.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "targetAttachment", null);
      _defineProperty(this, "attachment", null);
      _defineProperty(this, "hasOverlay", true);
      _defineProperty(this, "tetherTarget", null);
    }
    get targetAttachmentClass() {
      let targetAttachment = this.targetAttachment || '';
      // Convert tether-styled values like 'middle right' to 'right'
      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return `ember-modal-dialog-target-attachment-${(0, _string.dasherize)(targetAttachment)} emd-target-attachment-${(0, _string.dasherize)(targetAttachment)}`;
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (!this.attachment) {
        (0, _object.set)(this, 'attachment', 'middle center');
      }
      if (!this.targetAttachment) {
        (0, _object.set)(this, 'targetAttachment', 'middle center');
      }
    }
    get tetherClassPrefix() {
      return 'liquid-tether';
    }
    set tetherClassPrefix(val) {
      if (val) {
        return val;
      }
      return 'liquid-tether';
    }

    // element, css selector, view instance, 'viewport', or 'scroll-handle'
    // offset - passed in
    // targetOffset - passed in
    // targetModifier - passed in
  }, _applyDecoratedDescriptor(_class2.prototype, "targetAttachmentClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "targetAttachmentClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "tetherClassPrefix", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "tetherClassPrefix"), _class2.prototype), _class2)) || _class);
});