define("ember-cli-loaders/components/loader-base", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.class}}>
    {{#each this.divs}}<div></div>{{/each}}
  </div>
  
  */
  {
    "id": "O0MAeJYe",
    "block": "[[[10,0],[15,0,[30,0,[\"class\"]]],[12],[1,\"\\n  \"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"divs\"]]],null]],null],null,[[[10,0],[12],[13]],[]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"each\",\"-track-array\"]]",
    "moduleName": "ember-cli-loaders/components/loader-base.hbs",
    "isStrictMode": false
  });
  class LoaderBase extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "divCount", 0);
      _defineProperty(this, "class", 'loader-base');
    }
    get divs() {
      return Array(this.divCount).fill(undefined);
    }
  }
  _exports.default = LoaderBase;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LoaderBase);
});