define("ember-cli-ifa/helpers/asset-map", ["exports", "@ember/component/helper", "@ember/service", "@ember/object"], function (_exports, _helper, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _helper.default.extend({
    assetMap: (0, _service.inject)(),
    compute(params) {
      const file = params[0] || "";
      if (!file) {
        return;
      }
      return (0, _object.get)(this, 'assetMap').resolve(file);
    }
  });
});