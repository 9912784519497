define("ember-cli-ifa/utils/get-asset-map-data", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAssetMapData;
  function getAssetMapData() {
    if (typeof FastBoot !== "undefined") {
      let assetMap = (0, _require.default)("ember-cli-ifa/fastboot-asset-map");
      return assetMap.default;
    }
    let metaTag = document.querySelector("meta[name='ember-cli-ifa:assetMap']");
    if (!metaTag) {
      console.warn('<meta name="ember-cli-ifa:assetMap"> tag is missing.');
      return;
    }
    const assetMapString = metaTag.content;
    if (assetMapString) {
      return JSON.parse(decodeURIComponent(assetMapString));
    }
  }
});